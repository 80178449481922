import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Modal, PrimaryButton, DefaultButton, ProgressIndicator } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import ConfirmationDialog from "./ConfirmationDialog";
import { getFileSize } from "../../system/utils";
import { useFileUpload } from "system/hooks";
import { FileWithProgress } from "system/types/interfaces";
import { ErrorMessage, SuccessMessage, FileIcon } from ".";

interface FileUploadModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
  clientName?: string;
  clientShortName: string;
  folderPath?: string;
}

const MAX_FILE_SIZE = parseInt(process.env.REACT_APP_MAX_FILE_SIZE || "10737418240", 10);
const MAX_FILE_SIZE_FOR_TEXT = (MAX_FILE_SIZE / (1024 * 1024 * 1024)).toFixed(0);

const FileUploadModal = ({ isOpen, onDismiss, onSuccess, clientName, clientShortName, folderPath }: FileUploadModalProps) => {
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState<boolean>(false);
  const [maxFileSizeExceeded, setMaxFileSizeExceeded] = useState<boolean>(false);
  const { filesWithProgress, addFile, removeFile, resetFiles, uploadFiles, cancelUpload, uploadErrorMessage, setUploadErrorMessage } =
    useFileUpload(clientShortName, folderPath);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      filesWithProgress.forEach((fileWithProgress) => {
        if (acceptedFiles.some((file) => file.name === fileWithProgress.file.name)) {
          removeFile(fileWithProgress.file.name);
        }
      });

      acceptedFiles.forEach(addFile);
    },
    [addFile, filesWithProgress, removeFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDragEventsBubbling: true,
    maxSize: MAX_FILE_SIZE,
    onDropRejected: () => setMaxFileSizeExceeded(true),
  });

  const onConfirmDismiss = () => {
    resetFiles();
    setIsConfirmDialogVisible(false);
    onDismiss();
    onSuccess();
  };

  const renderActionButton = (fileWithProgress: FileWithProgress): JSX.Element => {
    if (fileWithProgress.isUploading) {
      return <Icon className="fileList__button--cancel" iconName="StopSolid" onClick={() => cancelUpload(fileWithProgress.file.name)} />;
    } else if (fileWithProgress.isCompleted && !fileWithProgress.isRequestCompleted) {
      return <Icon iconName="ReportHacked" className="fileList__button--verifying" />;
    } else if (fileWithProgress.isRequestCompleted) {
      return <Icon iconName="CheckMark" className="fileList__button--completed" />;
    }
    return <Icon className="fileList__button" iconName="Cancel" onClick={() => removeFile(fileWithProgress.file.name)} />;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={(ev) => {
          if (ev && isOpen && ev.type === "keydown" && (ev as unknown as KeyboardEvent).code === "Escape") {
            return;
          }

          setIsConfirmDialogVisible(true);
        }}
        containerClassName="uploadModal"
        scrollableContentClassName="uploadModal__scrollableContent"
        allowTouchBodyScroll={true}
        styles={{
          scrollableContent: {
            height: filesWithProgress.length > 0 ? "auto" : "35vh",
          },
        }}
      >
        <div className="uploadModal__header">
          <h4 className="uploadModal__header--title">Upload Files{clientName && clientName?.length > 0 ? ` for ${clientName}` : ""}</h4>
          <Icon
            className="uploadModal__header--icon"
            iconName="Cancel"
            onClick={() => {
              setIsConfirmDialogVisible(true);
            }}
          />
        </div>

        <div {...getRootProps({ className: `dropzone ${isDragActive ? "dropzoneActive" : ""}` })}>
          <input {...getInputProps()} />
          {!isDragActive ? (
            <>
              <Icon className="dropzone__icon" iconName="CloudUpload" />
              <p className="dropzone__text">
                Drag and drop <span className="dropzone__midtext">or </span> <span className="dropzone__button"> Browse</span>
              </p>
              <p className="dropzone__subtext">Your file cannot be larger than {MAX_FILE_SIZE_FOR_TEXT} GB.</p>
            </>
          ) : (
            <>
              <Icon className="dropzone__icon" iconName="CloudUpload" />
              <p className="dropzone__droptext">Drop your files in the box!!</p>
            </>
          )}
        </div>

        <div
          className="fileList"
          style={{ height: filesWithProgress.length > 3 ? "16rem" : filesWithProgress.length > 0 ? "auto" : "0rem" }}
        >
          {filesWithProgress.map((fileWithProgress, index) => (
            <div key={fileWithProgress.file.name} className="fileList__item">
              <span className="fileList__icon">
                <FileIcon fileType={fileWithProgress.file.type} />
              </span>
              <div className="fileList__details">
                <span className="fileList__name">{fileWithProgress.file.name}</span>
                <div className="fileList__progress">
                  <ProgressIndicator className="fileList__progress--bar" percentComplete={fileWithProgress.progress / 100} />
                  <span className="fileList__progress--percent">{fileWithProgress.progress}%</span>
                </div>
                <span className="fileList__size">
                  {getFileSize(fileWithProgress.file.size)}
                  <span className="fileList__size--status">
                    {fileWithProgress.isCompleted && !fileWithProgress.isRequestCompleted ? "- Verifyng the file..." : ""}
                  </span>
                </span>
              </div>
              {renderActionButton(fileWithProgress)}
            </div>
          ))}
        </div>

        <div className="uploadModal__footer">
          <DefaultButton
            className="uploadModal__footer--cancel"
            text="Cancel"
            onClick={() => {
              setIsConfirmDialogVisible(true);
            }}
          />
          <PrimaryButton
            className="uploadModal__footer--confirm"
            text={` ${filesWithProgress.length > 0 ? "Upload Files" : "Add Files"}`}
            onClick={uploadFiles}
            aria-disabled
            disabled={filesWithProgress?.every((x) => x.isUploading) || filesWithProgress?.every((x) => x.isCompleted)}
          />
        </div>
      </Modal>
      <ConfirmationDialog
        title="Cancel?"
        subText="Are you sure you want to cancel uploading the files?"
        isVisible={isConfirmDialogVisible}
        onConfirm={onConfirmDismiss}
        onCancel={() => {
          setIsConfirmDialogVisible(false);
        }}
      />

      <SuccessMessage
        isVisible={filesWithProgress.length > 0 && filesWithProgress?.every((x) => x.isRequestCompleted)}
        onConfirm={onConfirmDismiss}
        title={"Upload Successful!"}
        message={"All files have been successfully uploaded. "}
      />

      <ErrorMessage
        isVisible={maxFileSizeExceeded || uploadErrorMessage !== null}
        onConfirm={() => {
          setMaxFileSizeExceeded(false);
          setUploadErrorMessage(null);
        }}
        title={`${maxFileSizeExceeded ? "Max files size exceeded!" : "Upload Failed!"}`}
        message={`${
          maxFileSizeExceeded
            ? `Max files size exceeded. Please upload files with size less than ${MAX_FILE_SIZE_FOR_TEXT} GB.`
            : uploadErrorMessage
        }`}
      />
    </>
  );
};

export default FileUploadModal;
